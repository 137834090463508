export const ADMINS = ['tomas@tomasdavidye.tech', 'isaiah@onlinesellingpartner.com']

export const isAdmin = (email: string): boolean => {
    return ADMINS.includes(email.toLowerCase())
}

export const isLoggedIn = (email: string): boolean => {
    return !(email.toLowerCase() === noAuthEmail.toLowerCase())
}

export const noAuthEmail = 'noauth@email.com';
export const noAuthWebsite = 'unknown-website';
export const noAuthName = 'unknown-user';