import * as React from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {Form, Input, Icon, Button, notification, Popover, Spin, Col, Row} from 'antd';

/** Presentational */
import FormWrapper from '../../Components/Styled/FormWrapper';

/** App theme */
import {colors} from '../../Themes/Colors';
import CountryPhoneInput, {ConfigProvider, CountryPhoneInputValue} from 'antd-country-phone-input';
import en from 'world_countries_lists/data/en/world.json';
import {DynamoDBWrapper} from "../../AWS/DynamoDBWrapper";
import * as AWS from "aws-sdk";
import awsconfig from "../../aws-exports";

type Props = {
    form: any;
};

type State = {
    confirmDirty: boolean;
    redirect: boolean;
    loading: boolean;
    email: string;
    phoneNumber: object
};

type UserFormData = {
    fname: string;
    lname: string;
    password: string;
    email: string;
    website: string;
    phoneNumber: CountryPhoneInputValue;
};

const passwordValidator = require('password-validator');

// create a password schema
const schema = new passwordValidator();

schema
    .is()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols();

class SignUpContainer extends React.Component<Props, State> {
    state = {
        confirmDirty: false,
        redirect: false,
        loading: false,
        email: '',
        phoneNumber: {
            code: 1,
            short: 'US',
            phone: ''
        } as CountryPhoneInputValue
    };




    handleOpenNotification = (type: string, title: string, message: string): void => {
        switch (type) {
            case 'success':
                notification['success']({
                    message: title,
                    description: message,
                    placement: 'topRight',
                    duration: 1.5,
                    onClose: () => {
                        this.setState({redirect: true});
                    }
                });
                break;

            case 'error':
                notification['error']({
                    message: title,
                    description: message,
                    placement: 'topRight',
                    duration: 1.5
                });
                break;
        }
    };

    handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err: Error, values: UserFormData) => {
            if (!err) {
                console.log(values)
                let {fname, lname, password, email, website} = values;
                // show loader
                this.setState({loading: true});

                Auth.signUp({
                    username: email,
                    password,
                    attributes: {
                        email
                    }
                })
                    .then(() => {

                        this.setState({email});
                        this.setState({redirect: true});
                    })
                    .catch(err => {
                        notification.error({
                            message: 'Error',
                            description: 'Error signing up user',
                            placement: 'topRight',
                            duration: 1.5
                        });

                        console.log(err)

                        this.setState({
                            loading: false
                        });
                    });
            }
        });
    };

    handleConfirmBlur = (event: React.FormEvent<HTMLInputElement>) => {
        const {value} = event.currentTarget;

        this.setState({confirmDirty: this.state.confirmDirty || !!value});
    };

    compareToFirstPassword = (rule: object, value: string, callback: (message?: string) => void) => {
        const {form} = this.props;

        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule: object, value: string, callback: (message?: string) => void) => {
        const form = this.props.form;
        const validationRulesErrors = schema.validate(value, {list: true});

        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        if (validationRulesErrors.length > 0) {
            callback(this.formatPasswordValidateError(validationRulesErrors));
        }
        callback();
    };

    formatPasswordValidateError = (errors: Array<string>) => {
        for (let i = 0; i < errors.length; i++) {
            if (errors[i] === 'min') {
                return 'password length should be a at least 8 characters';
            } else if (errors[i] === 'lowercase') {
                return 'password should contain lowercase letters';
            } else if (errors[i] === 'uppercase') {
                return 'password should contain uppercase letters';
            } else if (errors[i] === 'digits') {
                return 'password should contain digits';
            } else if (errors[i] === 'symbols') {
                return 'password should contain symbols';
            }
        }
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {redirect, loading} = this.state;

        const title = 'Password Policy';
        const passwordPolicyContent = (
            <React.Fragment>
                <h4>Your password should contain: </h4>
                <ul>
                    <li>Minimum length of 8 characters</li>
                    <li>Numerical characters (0-9)</li>
                    <li>Special characters</li>
                    <li>Uppercase letter</li>
                    <li>Lowercase letter</li>
                </ul>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <FormWrapper onSubmit={this.handleSubmit}>

                    {/*<Form.Item>*/}
                    {/*    {getFieldDecorator('fname', {*/}
                    {/*        rules: [*/}
                    {/*            {*/}
                    {/*                required: true,*/}
                    {/*                message: 'Please input your first name!'*/}
                    {/*            }*/}
                    {/*        ]*/}
                    {/*    })(*/}
                    {/*        <Input*/}
                    {/*            prefix={<Icon type="user" style={{color: colors.transparentBlack}}/>}*/}
                    {/*            placeholder="First Name"*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item>*/}
                    {/*    {getFieldDecorator('lname', {*/}
                    {/*        rules: [*/}
                    {/*            {*/}
                    {/*                required: true,*/}
                    {/*                message: 'Please input your last name!'*/}
                    {/*            }*/}
                    {/*        ]*/}
                    {/*    })(*/}
                    {/*        <Input prefix={<Icon type="user" style={{color: colors.transparentBlack}}/>}*/}
                    {/*               placeholder="Last Name"/>*/}
                    {/*    )}*/}
                    {/*</Form.Item>*/}

                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: [{required: true, message: 'Please input your messaging!'}]
                        })(<Input prefix={<Icon type="mail" style={{color: colors.transparentBlack}}/>}
                                  placeholder="Email"/>)}
                    </Form.Item>

                    {/*TODO: Maybe we will want a phone number in the future*/}
                    {/*<Form.Item>*/}
                    {/*    <ConfigProvider locale={en}>*/}
                    {/*        <CountryPhoneInput*/}
                    {/*            inline={true}*/}
                    {/*            placeholder={'Phone Number'}*/}
                    {/*            value={this.state.phoneNumber}*/}
                    {/*            onChange={(e) => {*/}
                    {/*                this.setState({phoneNumber: e});*/}
                    {/*            }}*/}
                    {/*            pattern="[0-9]+"*/}
                    {/*            prefix={<Icon type="phone" style={{color: colors.transparentBlack}}/>}*/}
                    {/*        />*/}
                    {/*    </ConfigProvider>*/}
                    {/*</Form.Item>*/}


                    {/*<Form.Item>*/}
                    {/*    {getFieldDecorator('website', {*/}
                    {/*        rules: [*/}
                    {/*            {*/}
                    {/*                required: true,*/}
                    {/*                message: 'Please input your Company Name!'*/}
                    {/*            }*/}
                    {/*        ]*/}
                    {/*    })(*/}
                    {/*        <Input*/}
                    {/*            prefix={<Icon type="cloud" style={{color: colors.transparentBlack}}/>}*/}
                    {/*            placeholder="Company Name (Amazon, Google ... or self employed)"*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*</Form.Item>*/}

                    <Form.Item>
                        <Popover placement="bottomRight" title={title} content={passwordPolicyContent} trigger="focus">
                            {getFieldDecorator('password', {
                                rules: [
                                    {required: true, message: 'Please input your Password!'},
                                    {
                                        validator: this.validateToNextPassword
                                    }
                                ]
                            })(
                                <Input
                                    prefix={<Icon type="lock" style={{color: colors.transparentBlack}}/>}
                                    type="password"
                                    placeholder="Password"
                                />
                            )}
                        </Popover>
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('confirm', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please confirm your password!'
                                },
                                {
                                    validator: this.compareToFirstPassword
                                }
                            ]
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{color: colors.transparentBlack}}/>}
                                type="password"
                                placeholder="Confirm Password"
                                onBlur={this.handleConfirmBlur}
                            />
                        )}
                    </Form.Item>

                    <Form.Item className="text-center">
                        <Row>
                            <Col lg={24}>
                                <Button style={{width: '100%'}} type="primary" disabled={loading} htmlType="submit">
                                    {loading ? <Spin
                                        indicator={<Icon type="loading" style={{fontSize: 24}} spin/>}/> : 'Sign Up!'}
                                </Button>
                            </Col>
                            <Col lg={24}>
                                Or <Link to="/login">login to your account!</Link>
                            </Col>
                        </Row>
                    </Form.Item>
                </FormWrapper>
                {redirect && (
                    <Redirect
                        to={{
                            pathname: '/login',
                        }}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default Form.create()(SignUpContainer);
