import {useEffect, useState} from "react";
import {backendCall} from "./Utils";



export const useAPI = (path: string, params: any, transform: (response: any) => any = response => response) => {
    const [data, setData] = useState({} as any);
    const [error, setError] = useState({} as any);
    const [dataReady, setDataReady] = useState(false);


    useEffect(() => {
        backendCall(path, params, transform).then(({result, error}) => {
            if(!error){
                setData(result);
                setDataReady(true);
            } else {
                setError(error)
            }
        })
    },[])



    return {data, dataReady, error}


}
