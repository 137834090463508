import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {Button, Icon, Input, Table, Tag, Tooltip, DatePicker, Spin, Select} from "antd";
import {LineChart, Line, XAxis, Tooltip as ChartTooltip, Legend} from 'recharts';
import {backendCall, getDateRange, getSanitisedTerm, urlEncodeTerm} from "../../API/Utils";
import moment from "moment";
import useAuthUser from "../../Hooks/auth/useAuthUser";
import {isLoggedIn} from "../../Hooks/auth/auth-utils";
import SignUpContainer from "../SignUpContainer";
import {SocialIcon} from "react-social-icons";


const {RangePicker} = DatePicker;
const {Option} = Select;


const TermsContainer = () => {


    const [termData, setTermData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingImg, setLoadingImg] = useState(true);

    const {authUser, authPending} = useAuthUser()

    const [sortColumn, setSortColumn] = useState('volume_growth_percent');
    const [rangeFilterColumn, setRangeFilterColumn] = useState('volume_growth_percent');
    const [rangeFilterUpperBound, setRangeFilterUpperBound] = useState('');
    const [rangeFilterLowerBound, setRangeFilterLowerBound] = useState('');
    const [sortDirection, setSortDirection] = useState('desc');
    const [termPrefix, setTermPrefix] = useState('');
    const [timeGranularity, setTimeGranularity] = useState('weekly');
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(60);
    const [totalItems, setTotalItems] = useState(8555);
    const [numAsins, setNumAsins] = useState(2);
    const [chartHeight, setChartHeight] = useState(150);
    const [chartWidth, setChartWidth] = useState(250);
    const [asinImgSize, setAsinImgSize] = useState(70);
    const [startDate, setStartDate] = useState(getDateRange(timeGranularity).startDate);
    const [endDate, setEndDate] = useState(getDateRange(timeGranularity).endDate);


    const columnTitle = (columnTitle: string, columnTooltip: string) => {
        return () => <Tooltip
            title={columnTooltip}>
            {columnTitle}
            <Icon
                theme={'twoTone'}
                type={'question-circle'}
                style={{marginLeft: '5px'}}
            >

            </Icon>
        </Tooltip>
    }

    const tableColumns = [
        {
            title: columnTitle('Term', 'A query submitted to the Amazon search engine. This tool aims to find the terms unexpectedly became popular and help users make informed product decisions.'),
            dataIndex: 'term',
            key: 'term',
            width: '10%',
            render: (t: string) => {
                const termSanitized = getSanitisedTerm(t);


                return <div>
                    <a
                        target={'_blank'}
                        href={`https://www.amazon.com/gp/search?ie=UTF8&tag=trendfriend02-20&linkCode=ur2&linkId=0061b83746765f778b0c0744cb618138&camp=1789&creative=9325&index=aps&keywords=${termSanitized}`}
                    >
                        <p style={{
                            fontWeight: "bold",
                            fontSize: "16px"
                        }}>"{termSanitized}"</p>
                    </a>
                    <a href={`/trending/${urlEncodeTerm(t)}`} target={'_blank'}>
                        <Tag color={'purple'} key={termSanitized} style={{
                            cursor: 'pointer'
                        }}>
                            DETAILS
                        </Tag>
                    </a>

                </div>

            }
        },
        {
            title: columnTitle('Chart', "A visual time series of how a search rank evolved in time for a given search term." +
                "A steep downward trend is exciting because it means that a term suddenly became popular"),
            dataIndex: 'chartData',
            key: 'chartData',
            render: (_: any, params: any) => {
                const chartData = params['chartData']
                const slope = params['slope']
                const constant = params['const']
                const dates = params['dates']


                const volumeGrowthPercent = params['volume_growth_percent'];
                const volumeGrowthPercentAbs = Math.abs(volumeGrowthPercent);
                const sign = volumeGrowthPercent >= 0 ? '+' : '-';
                const comment = volumeGrowthPercent >= 0 ? 'Growth' : "Decline"
                const color = volumeGrowthPercent >= 0 ? 'green' : "red"


                return <div
                    style={{
                        width: 200
                    }}
                >
                    <div
                        style={{
                            color: color,
                            fontWeight: "bold",
                            fontSize: "20px"
                        }}
                    >
                        {sign} {(1 * volumeGrowthPercentAbs).toFixed(2)} % {comment}
                    </div>
                    <LineChart width={chartWidth} height={chartHeight}
                               data={chartData.map((x: number, index: number) => {
                                   return {
                                       search_volume: x,
                                       trend: (+constant + index * slope).toFixed(0),
                                       date: dates[index].substring(0, 10)
                                   }
                               })}>
                        <Legend/>
                        <ChartTooltip
                            position={{
                                y: 50
                            }}
                        />
                        <XAxis dataKey="date"/>
                        <Line type="monotone" dataKey="search_volume" stroke="#8884d8" strokeWidth={2} dot={false}/>
                        <Line type="monotone" dataKey="trend" stroke="#ffcccb" strokeWidth={2} dot={false}/>
                    </LineChart>
                </div>
            }
        },
        {
            title: columnTitle('Growth', 'Links to Social Media apps for the given search term'),
            dataIndex: 'volume_growth_percent',
            key: 'volume_growth_percent',
            width: '12%',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            sorter: (a: any, b: any) => a.volume_growth_percent - b.volume_growth_percent,
            render: (t: number) => `${(1 * t).toFixed(2)} %`
        },
        {
            title: columnTitle("ASINs", "Images of top clicked ASINs. Click an image to redirect to its official Amazon website."),
            dataIndex: 'asinImages',
            key: 'asinImages',
            width: '25%',
            render: (_: any, {asinImages}: any) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around'
                    }}
                >
                    {asinImages.map((asin: string) => {
                        return (
                            <a href={`https://www.amazon.com/dp/${asin}?tag=trendfriend02-20&_encoding=UTF8&linkCode=ur2&linkId=806a85d9f534e73e015afff7b43fa56a&camp=1789&creative=9325`}
                               target={'_blank'}>
                                {loadingImg ? <Spin/> :
                                    <div>
                                        <img
                                            src={`https://images.amazon.com/images/P/${asin}.01._SCLZZZZZZZ_.jpg`}
                                            width={`${asinImgSize}px`}
                                            height={`${asinImgSize}px`}
                                            alt={" "}
                                            style={{
                                                margin: '3%',
                                                borderRadius: '20px',
                                                borderWidth: '3px',
                                                borderColor: '#ffbb96',
                                                borderStyle: "solid"
                                            }}
                                        />

                                        <p>{asin}</p>
                                    </div>
                                }
                            </a>

                        );
                    })}
                </div>
            ),
        },
        {
            title: columnTitle('Find Out Why', 'Links to Social Media apps for the given search term to help you track down the source of the trend'),
            dataIndex: 'term',
            key: 'volume_growth_percent',
            width: '25%',
            render: (t: string) => {
                const termSanitized = getSanitisedTerm(t);

                return <div>
                    <div>
                        <SocialIcon
                            url={`https://www.tiktok.com/search?q=${termSanitized}`}
                            target={'_blank'}
                        />
                        <SocialIcon
                            url={`https://www.youtube.com/results?search_query=${termSanitized}`}
                            target={'_blank'}
                        />
                        <SocialIcon
                            url={`https://www.google.com/search?q=${termSanitized}`}
                            target={'_blank'}
                        />
                    </div>
                </div>
            }
        }

    ];

    const handleFilter = (e: any) => {
        setLoading(true)
        setPageNumber(1)
        console.log('Term Prefix = ', termPrefix)

        fetch(1, sortDirection, sortColumn, termPrefix)
    }

    const handleChange = (pagination: any, filters: any, sorter: any) => {
        setTermData([]);
        console.log('pagination = ', pagination)
        console.log('filters = ', filters)
        console.log('sorter = ', sorter)


        setLoading(true)
        const pageNumber = pagination.current
        let _sortDirection = sortDirection
        let _sortColumn = sortColumn

        if (sorter.order) {
            if (sorter.order === 'descend') {
                _sortDirection = 'desc'
            }
        }

        if (sorter.columnKey) {
            _sortColumn = sorter.columnKey

        }

        fetch(pageNumber, _sortDirection, _sortColumn, termPrefix)

    }


    const fetch = (_pageNumber: number, _sortDirection: string, _sortColumn: string, _termPrefix: string) => {
        backendCall('/get-time-series', {
            is_test: '0',
            email: authUser.email,
            granularity: timeGranularity,
            sort_column: _sortColumn,
            sort_direction: _sortDirection,
            page_size: pageSize,
            page_number: _pageNumber,
            term_prefix: _termPrefix,
            start_date: startDate,
            end_date: endDate,
            range_filter_field: rangeFilterColumn,
            range_filter_upper: rangeFilterUpperBound,
            range_filter_lower: rangeFilterLowerBound,
        }, r => r).then((r: any) => {
            let newTermData: any = []
            let index = 0
            let totalNumberOfTerms = 0;
            if (r['result'] && r['result']['terms']) {


                const terms = r['result']['terms'];
                totalNumberOfTerms = r['result']['total_num_terms'];

                Object.keys(terms).forEach((term: string) => {
                    let termDetails = terms[term]

                    newTermData.push({
                        'index': index,
                        'term': term,
                        'dates': termDetails['date'],
                        'chartData': termDetails['estimated_search_volume'],
                        'best_rank': termDetails['best_rank'],
                        'last_rank': termDetails['last_rank'],
                        'slope': termDetails['slope'].toFixed(2),
                        'const': termDetails['const'].toFixed(2),
                        'exampleAsins': termDetails['asins'].slice(0, numAsins),
                        'asinImages': termDetails['asins'].slice(0, numAsins),
                        'first_volume': termDetails['first_volume'],
                        'last_volume': termDetails['last_volume'],
                        'best_volume': termDetails['best_volume'],
                        'worst_volume': termDetails['worst_volume'],
                        'volume_growth_percent': termDetails['volume_growth_percent'].toFixed(3),
                        'volume_growth': termDetails['volume_growth'],
                    })

                    index += 1;
                })
            }


            setTermData(newTermData);

            setSortDirection(_sortDirection);
            setSortColumn(_sortColumn);
            setPageNumber(_pageNumber);
            setTermPrefix(_termPrefix);
            setTotalItems(totalNumberOfTerms);

            setLoading(false);
            setLoadingImg(true);
            setTimeout(() => setLoadingImg(false), 1000);
        })
    };

    useEffect(() => {
        fetch(pageNumber, sortDirection, sortColumn, termPrefix)


    }, [])

    const onChange = (date: any, dateString: Array<string>) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    }


    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-evenly'
            }}
        >
            <div
                style={{
                    width: '20%',
                    textAlign: 'center',
                    marginTop: '50px',
                    borderRight: "2px solid #1890ff"
                }}
            >
                <div>
                    <img
                        src={"https://ecomtrendfinder-resources.s3.amazonaws.com/EcomTrendFinderLogo.jpeg"}
                        width={"50%"}
                    />
                    <div
                        style={{
                            marginTop: '3%',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        {/*{isAdmin(authUser.email) && <a*/}
                        {/*    href={"/admin-view"}*/}
                        {/*    target={"_blank"}*/}
                        {/*    style={{*/}
                        {/*        zIndex: 100*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <Button*/}
                        {/*        type={"default"}*/}
                        {/*        style={{marginLeft: "10px"}}*/}
                        {/*    >*/}
                        {/*        Admin View*/}
                        {/*    </Button>*/}
                        {/*</a>}*/}
                        <a
                            href={"https://www.loom.com/share/a9c87cff117c411cbfc19e10b65cf726"}
                            target={"_blank"}
                            style={{
                                zIndex: 100
                            }}
                        >
                            <Button
                                type={"primary"}
                                style={{marginLeft: "2px"}}
                            >
                                Tutorial
                            </Button>
                        </a>
                        <a
                            href={"https://docs.google.com/forms/d/1rOh9krsLvcYGmzExGSl-yOvMw43Wzrg9uRyYNGtCHrs"}
                            target={"_blank"}
                            style={{
                                zIndex: 100
                            }}
                        >
                            <Button
                                type={"danger"}
                                style={{marginLeft: "2px"}}
                            >
                                Feedback
                            </Button>

                        </a>
                    </div>
                </div>

                <h1
                    style={{
                        marginTop: '5%',
                        paddingTop: '5%',
                        borderTop: "2px solid #1890ff"
                    }}

                >Filters</h1>
                <div style={{marginTop: '5%', width: '90%'}}>
                    <div>
                        {columnTitle('Time Granularity', 'Time granularity of the data. Daily data is more accurate but less granular. Weekly data is less accurate but more granular.')()}
                    </div>
                    <Select
                        placeholder={'coffee'}
                        defaultValue={timeGranularity}
                        onChange={(value: string) => {
                            setTimeGranularity(value)
                            setStartDate(getDateRange(value).startDate);
                            setEndDate(getDateRange(value).endDate);
                        }}
                        style={{
                            width: '50%',
                        }}
                    >
                        <Option value={'daily'}>Daily</Option>
                        <Option value={'weekly'}>Weekly</Option>
                        <Option value={'monthly'}
                                disabled={!isLoggedIn(authUser.email)}>{isLoggedIn(authUser.email) ? "Monthly" : "Sign Up for Monthly"}</Option>

                    </Select>
                </div>

                <div style={{marginTop: '5%', width: '90%'}}>
                    {columnTitle('Term Prefix', 'Filter terms by prefix. For example, if you want to filter all terms that contain with "coffee", enter "coffee" in the box below.')()}
                    <Input
                        placeholder={'coffee'}
                        value={termPrefix}
                        onChange={(e) => setTermPrefix(e.target.value)}
                    />
                </div>
                <div style={{marginTop: '5%', width: '90%'}}>
                    <div>
                        {columnTitle('Range Filter', 'Select a column and range values to filter terms (just like you would on an excel spreadsheet).')()}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            width: '100%'
                        }}
                    >
                        <Input
                            placeholder={''}
                            value={rangeFilterLowerBound}
                            onChange={(e) => setRangeFilterLowerBound(e.target.value)}
                        />
                        <Icon
                            type="left"
                            style={{
                                marginTop: '10px'
                            }}

                        />
                        <Select
                            value={rangeFilterColumn}
                            onChange={(value: string) => setRangeFilterColumn(value)}
                            style={{
                                width: '150px',
                            }}
                        >
                            <Option value="slope">Slope</Option>
                            <Option value="volume_growth">Growth</Option>
                            <Option value="volume_growth_percent">Growth %</Option>

                        </Select>
                        <Icon
                            type="left"
                            style={{
                                marginTop: '10px'
                            }}

                        />
                        <Input
                            placeholder={''}
                            value={rangeFilterUpperBound}
                            onChange={(e) => setRangeFilterUpperBound(e.target.value)}
                        />
                    </div>
                </div>
                <div style={{marginTop: '5%', width: '90%'}}>
                    {columnTitle('Term Category', '[COMING SOON], Select a category to filter terms by. If no category is selected, all terms will be shown.')()}

                    <Input
                        placeholder={'Beverages (COMING SOON!!! )'}
                        type={'dropdown'}
                        disabled={true}
                    />
                </div>
                <div style={{marginTop: '5%', width: '90%'}}>
                    {columnTitle('Date Range', 'Find the most exploding trends in a given period by specifying a start and end date for your search')()}
                    <RangePicker
                        onChange={onChange}
                        value={[moment(startDate), moment(endDate)]}
                        disabledDate={(current: any) => {
                            return current && (
                                current > moment(getDateRange(timeGranularity).endDate).endOf('day')
                                || current < moment(getDateRange(timeGranularity).startDate).startOf('day'))
                        }
                        }
                    />
                </div>
                <div
                    style={{
                        marginTop: '5%',
                        display: 'flex',
                        justifyContent: 'space-evenly'
                    }}
                >
                    <Button
                        type={'primary'}
                        onClick={handleFilter}
                    >
                        Search
                    </Button>
                </div>

                <div
                    style={{
                        marginTop: '5%',
                        paddingTop: '5%',
                        borderTop: "2px solid #1890ff"
                    }}
                >
                    {isLoggedIn(authUser.email) ? null : <div>
                        <h1>Sign Up to Unlock</h1>
                        <ul
                            style={{
                                textAlign: 'left'
                            }}
                        >
                            <li>Monthly Data</li>
                            <li>Daily Trend Newsletter</li>
                            <li>Access to Trend Finder Community</li>
                        </ul>
                        <SignUpContainer/>

                    </div>}
                </div>
            </div>

            <div
                style={{
                    width: '75%'
                }}
            >
                <Table
                    dataSource={termData}
                    columns={tableColumns}
                    pagination={{
                        pageSize: pageSize,
                        current: pageNumber,
                        total: totalItems,
                        position: 'both',
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                    }}
                    loading={loading}
                    onChange={handleChange}
                    scroll={{
                        y: 800
                        ,
                        scrollToFirstRowOnChange: true
                    }}

                />
            </div>

        </div>

    )
}
export default withRouter(TermsContainer);
