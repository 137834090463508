import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {useParams} from "react-router-dom";
import ChartContainer from "../ChartContainer";
import {Button, Spin} from "antd";
import {getSanitisedTerm, urlDecodeTerm} from "../../API/Utils";
import useAuthUser from "../../Hooks/auth/useAuthUser";
import {isLoggedIn} from "../../Hooks/auth/auth-utils";
import SignUpContainer from "../SignUpContainer";
import RelatedTermsContainer from "../RelatedTermsContainer";


const TermDetailsMobileContainer = () => {
    const {urlEncodeTermId} = useParams();
    const [termId, setTermId] = useState("");

    const [asins, setAsins] = useState([])
    const [maxNumASINs, setMaxNumASINs] = useState(2);
    const [loadingImg, setLoadingImg] = useState(true);
    const [asinImgSize, setAsinImgSize] = useState(75);
    const [chartWidth, setChartWidth] = useState(300);

    const {authUser, authPending} = useAuthUser()

    useEffect(() => {
        if (asins.length > 0) {
            setTimeout(() => setLoadingImg(false), 1000);
        }
    }, [asins])

    useEffect(() => {
        console.log("urlEncodeTermId", urlEncodeTermId)
        if (urlEncodeTermId) {
            console.log("urlEncodeTermId", urlEncodeTermId)
            setTermId(urlDecodeTerm(urlEncodeTermId));
        }
    }, [urlEncodeTermId])

    return (
        <div
            style={{
                textAlign: "center",
                marginTop: "5%",
            }}
        >
            <div>
                <div>
                    <img
                        src={"https://ecomtrendfinder-resources.s3.amazonaws.com/EcomTrendFinderLogo.jpeg"}
                        width={"50%"}
                    />

                    <div
                        style={{
                            marginTop: '3%',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <a
                            href={"https://www.loom.com/share/a9c87cff117c411cbfc19e10b65cf726"}
                            target={"_blank"}
                            style={{
                                zIndex: 100
                            }}
                        >
                            <Button
                                type={"primary"}
                                style={{marginLeft: "2px"}}
                            >
                                Tutorial
                            </Button>
                        </a>
                        <a
                            href={"/search"}
                            style={{
                                zIndex: 100
                            }}
                        >
                            <Button
                                type={"danger"}
                                style={{marginLeft: "2px"}}
                            >
                                Back to Search
                            </Button>

                        </a>
                    </div>
                </div>


                <div
                    style={{
                        width: "90%",
                        margin: "5%",
                        borderTop: "2px solid #1890ff",
                    }}
                >
                    <div
                        style={{
                            marginTop: "5%"
                        }}
                    >

                        <a
                            target={'_blank'}
                            href={`https://www.amazon.com/gp/search?ie=UTF8&tag=trendfriend02-20&linkCode=ur2&linkId=0061b83746765f778b0c0744cb618138&camp=1789&creative=9325&index=aps&keywords=${getSanitisedTerm(termId)}`}
                        >
                            <p style={{
                                fontWeight: "bold",
                                fontSize: "20px"
                            }}>"{getSanitisedTerm(termId)}"</p>
                        </a>

                        {asins.length === 0 ? <Spin/> : <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly'
                            }}
                        >
                            <>
                                {asins.slice(0, maxNumASINs).map((asin: string) => {
                                    return (
                                        <a href={`https://www.amazon.com/dp/${asin}?&_encoding=UTF8&tag=trendfriend02-20&linkCode=ur2&linkId=806a85d9f534e73e015afff7b43fa56a&camp=1789&creative=9325`}
                                           target={'_blank'}>
                                            {loadingImg ? <Spin/> :
                                                <div>
                                                    <img
                                                        src={`https://images.amazon.com/images/P/${asin}.01._SCLZZZZZZZ_.jpg`}
                                                        width={`${asinImgSize}px`}
                                                        height={`${asinImgSize}px`}
                                                        style={{
                                                            margin: '3%',
                                                            borderRadius: '20px',
                                                            borderWidth: '3px',
                                                            borderColor: '#ffbb96',
                                                            borderStyle: "solid"
                                                        }}
                                                    />
                                                    <p>{asin}</p>
                                                </div>


                                            }
                                        </a>

                                    );
                                })}
                            </>
                        </div>}
                    </div>

                    {termId ? <div>
                        <div>

                        </div>
                        <div
                            style={{
                                marginTop: "2%"
                            }}
                        >

                            <div
                                style={{
                                    marginTop: '5%',
                                    paddingTop: '5%',
                                    borderTop: "2px solid #1890ff"
                                }}
                            >
                                <ChartContainer
                                    term={termId}
                                    granularity={"daily"}
                                    setAsins={setAsins}
                                    showStats={false}
                                    numAsins={0}
                                    chartWidth={chartWidth}
                                    hideTitle={true}
                                />
                            </div>

                            <div
                                style={{
                                    marginTop: '5%',
                                    paddingTop: '5%',
                                    borderTop: "2px solid #1890ff"
                                }}
                            >

                                <ChartContainer
                                    term={termId}
                                    granularity={"weekly"}
                                    showStats={false}
                                    numAsins={0}
                                    chartWidth={chartWidth}
                                    hideTitle={true}
                                />
                            </div>

                            <div
                                style={{
                                    marginTop: '5%',
                                    paddingTop: '5%',
                                    borderTop: "2px solid #1890ff"
                                }}
                            >
                                <ChartContainer
                                    term={termId}
                                    granularity={"monthly"}
                                    showStats={false}
                                    numAsins={0}
                                    onlySignUp={true}
                                    chartWidth={chartWidth}
                                    hideTitle={true}
                                />
                            </div>

                        </div>
                    </div> : null}

                    <div
                        style={{
                            marginTop: '5%',
                            paddingTop: '5%',
                            width: '100%',
                            borderTop: "2px solid #1890ff"
                        }}
                    >
                        {termId ? <RelatedTermsContainer
                            numRelatedTerms={10}
                            numWordsAsPrefix={2}
                            term={termId}
                        /> : null}

                    </div>

                    <div
                        style={{
                            marginTop: '5%',
                            paddingTop: '5%',
                            width: '100%',
                            borderTop: "2px solid #1890ff"
                        }}
                    >
                        {isLoggedIn(authUser.email) ? null : <div>
                            <h1>Sign Up to Unlock</h1>
                            <ul
                                style={{
                                    textAlign: 'left'
                                }}
                            >
                                <li>Monthly Data</li>
                                <li>Daily Trend Newsletter</li>
                                <li>Access to Trend Finder Community</li>
                            </ul>
                            <SignUpContainer/>

                        </div>}


                    </div>


                </div>
            </div>
        </div>

    )

}

export default withRouter(TermDetailsMobileContainer);
