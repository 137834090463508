import {Auth} from 'aws-amplify';
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {noAuthEmail, noAuthName, noAuthWebsite} from "./auth-utils";

const useAuthUser = () => {
    const [authPending, setAuthPending] = useState(true);
    const [authUser, setAuthUser] = useState({
        email: '',
        website: '',
        name: ''
    });

    const history = useHistory();


    useEffect(() => {
        Auth.currentAuthenticatedUser().then((userData) => {

            let user = {
                email: userData['attributes'].email,
                name: userData['attributes'].name,
                website: userData['attributes'].website
            }

            setAuthUser(user);
            setAuthPending(false);

        }, (err) => {
            console.log(err);
            setAuthUser({
                email: noAuthEmail,
                name: noAuthName,
                website: noAuthWebsite
            });
            setAuthPending(false);
        })
    }, []);

    return {authUser, authPending}
}


export default useAuthUser
