import styled from 'styled-components';
import { Form } from 'antd';

/** App Theme */
import { colors } from '../../Themes/Colors';

const FormWrapper = styled(Form)`
  border: 1px solid ${colors.grey};
  margin: 2% auto !important;
  padding: 42px 24px 50px !important;
  background: ${colors.white};
  width: 75%
`;

export default FormWrapper;
