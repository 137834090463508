import {TREND_FINDER_LAMBDA_API, TREND_FINDER_LIGHT_SAIL_API} from "../Utils/constants";

export const backendCall = async (url: string, params: any, transform: (response: any) => any = response => response) => {
    let fullURL = `${TREND_FINDER_LAMBDA_API}/${url}?`;

    Object.keys(params).forEach((key, index) => {
        fullURL = `${fullURL}&${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    });

    // console.log('FULL URL = ', fullURL);

    try {
        const response = await (await fetch(fullURL)).json();
        // console.log(response)
        const result = transform(response)
        // console.log(result)
        const error = null

        return {result, error}

    } catch (e) {
        const error = e
        console.error('Something happened when fetching URL = ', fullURL)
        console.log(e)
        const result = null
        return {result, error}
    }
}

export const todayInYYYYMMDD = () => {
    return new Date().toISOString().slice(0, 10);
}

export const todayInYYYYMMDDMinusDays = (numDays: number) => {
    const date = new Date();
    date.setDate(date.getDate() - numDays);
    return date.toISOString().slice(0, 10);
}

const DATE_RANGES: Map<string, any> = new Map<string, any>()
DATE_RANGES.set('daily', {
    startDate: '2023-05-15',
    endDate: todayInYYYYMMDD(),
});
DATE_RANGES.set('weekly', {
    startDate: '2023-01-01',
    endDate: todayInYYYYMMDD(),
});
DATE_RANGES.set('monthly', {
    startDate: '2021-01-01',
    endDate: todayInYYYYMMDD(),
});



export const getDateRange = (granularity: string) => {
    return DATE_RANGES.get(granularity);
}

const TERM_NAME_MAPPING: Map<string, string> = new Map<string, any>()
TERM_NAME_MAPPING.set('b002qi6jja|b01lz3rlpc|b09xmyftb7|b08h3jph74|b072b9', 'sky high washable mascara');
TERM_NAME_MAPPING.set('b013xkha4m|b08xzrxczm|b07xxphqzk|b09rwjblc7', 'sol de janeiro body care');
TERM_NAME_MAPPING.set('b07vr1ndsq|b08t47qvnr|b07pw4mthv|b07b895knd|b0914x', 'olaplex conditioner');

export const getSanitisedTerm = (term: string) => {
    if (!TERM_NAME_MAPPING.has(term)) {
        return term.slice(0, 50);
    } else {
        return TERM_NAME_MAPPING.get(term);
    }

}


export const urlEncodeTerm = (term: string) => {
    return term.replace(/ /g, '-');
}

export const urlDecodeTerm = (term: string) => {
    return term.replace(/-/g, ' ');
}
