import React, {useEffect, useState} from "react";
import {backendCall, getSanitisedTerm} from "../../API/Utils";
import {Spin} from "antd";
import {LineChart, Line, XAxis, Tooltip as ChartTooltip, Legend} from 'recharts';
import {isLoggedIn} from "../../Hooks/auth/auth-utils";
import useAuthUser from "../../Hooks/auth/useAuthUser";

type ChartProps = {
    term: string;
    granularity: string;
    showStats: boolean;
    numAsins: number;
    setAsins?: any;
    onlySignUp?: boolean;
    chartWidth?: number;
    hideTitle?: boolean;
}

const ChartContainer = (props: ChartProps) => {

    const [termData, setTermData] = useState({} as any);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dataExists, setDataExists] = useState(false);
    const [asins, setAsins] = useState([]);
    const [asinImageSize, setAsinImageSize] = useState(75);
    const [chartHeight, setChartHeight] = useState(150);
    const [chartWidth, setChartWidth] = useState(props.chartWidth ? props.chartWidth : 250);
    const [imgLoaded, setImgLoaded] = useState(false);
    const [onlySignUp, setOnlySignUp] = useState(!!props.onlySignUp);
    const [hideTitle, setHideTitle] = useState(!!props.hideTitle);

    const {authUser, authPending} = useAuthUser()

    useEffect(() => {
        backendCall('/get-time-series', {
            is_test: '0',
            email: authUser.email,
            granularity: props.granularity,
            sort_column: 'slope',
            sort_direction: 'desc',
            page_size: '10',
            page_number: '1',
            term_prefix: props.term,
            start_date: '2020-01-01',
            end_date: '2030-01-01',
            strict_match: '1',

        }, r => r).then((r: any) => {
            const termsInResponse = r['result']['terms']
            console.log("termsInResponse", termsInResponse)

            if (props.term in termsInResponse) {
                let termDetails = termsInResponse[props.term]

                setTermData({
                    'term': props.term,
                    'dates': termDetails['date'],
                    'chartData': termDetails['estimated_search_volume'],
                    'slope': termDetails['slope'].toFixed(2),
                    'const': termDetails['const'].toFixed(2),
                    'exampleAsins': termDetails['asins'],
                    'asinImages': termDetails['asins'],
                    'first_volume': termDetails['first_volume'],
                    'last_volume': termDetails['last_volume'],
                    'best_volume': termDetails['best_volume'],
                    'worst_volume': termDetails['worst_volume'],
                    'volume_growth_percent': termDetails['volume_growth_percent'],
                    'volume_growth': termDetails['volume_growth'],
                })

                if (props.setAsins) {
                    props.setAsins(termDetails['asins']);
                }

                setAsins(termDetails['asins']);
                setDataExists(true);


            } else {
                console.error("Error: no data for term", props.term)
                console.error("Response: ", r)
                setDataExists(false);

            }

            setDataLoaded(true);
            setTimeout(() => {
                setImgLoaded(true)
            }, 1000)


        })

    }, [])


    const volumeGrowthPercent = termData['volume_growth_percent'];
    const volumeGrowthPercentAbs = Math.abs(volumeGrowthPercent);
    const sign = volumeGrowthPercent >= 0 ? '+' : '-';
    const comment = volumeGrowthPercent >= 0 ? 'Growth' : "Decline"
    const color = volumeGrowthPercent >= 0 ? 'green' : "red"

    const chartData = termData['chartData']
    const slope = termData['slope']
    const constant = termData['const']
    const dates = termData['dates']


    return (
        <div>

            <div style={{
                textAlign: "center",
            }}>
                {hideTitle ? null : <div>
                    <a
                        target={'_blank'}
                        href={`https://www.amazon.com/gp/search?ie=UTF8&tag=trendfriend02-20&linkCode=ur2&linkId=0061b83746765f778b0c0744cb618138&camp=1789&creative=9325&index=aps&keywords=${getSanitisedTerm(props.term)}`}
                    >
                        <p style={{
                            fontWeight: "bold",
                            fontSize: "20px"
                        }}>"{getSanitisedTerm(props.term)}"</p>
                    </a>
                </div>}
                <h3>{props.granularity.toLocaleUpperCase()}</h3>
            </div>

            {
                !dataLoaded ? <Spin/> : !dataExists ? <h3

                >
                    No Data
                </h3> : !isLoggedIn(authUser.email) && onlySignUp ? <div
                >
                    <h3 style={{color: 'red'}}><a href={"/signup"}>Sign up </a> to unlock monthly data</h3>
                    <h3 style={{color: 'red'}}> and see if trend is only seasonal</h3>

                </div> : <div
                    style={{
                        width: "100",
                        textAlign: "center"
                    }}
                >
                    <div
                        style={{
                            margin: "5%",
                        }}
                    >
                        <h3>{termData['dates'][0].substring(0, 10)} ... {termData['dates'][termData['dates'].length - 1].substring(0, 10)}</h3>
                    </div>
                    <div
                        style={{
                            color: color,
                            fontWeight: "bold",
                            fontSize: "20px",
                            margin: "5%"
                        }}
                    >
                        {sign} {(1 * volumeGrowthPercentAbs).toFixed(2)} % {comment}
                    </div>
                    <LineChart width={chartWidth} height={chartHeight}
                               data={chartData.map((x: number, index: number) => {
                                   return {
                                       search_volume: x,
                                       trend: (+constant + index * slope).toFixed(0),
                                       date: dates[index].substring(0, 10)
                                   }
                               })}>
                        <Legend/>
                        <ChartTooltip
                            position={{
                                y: 50
                            }}
                        />
                        <XAxis dataKey="date"/>
                        <Line type="monotone" dataKey="search_volume" stroke="#8884d8" strokeWidth={2} dot={false}/>
                        <Line type="monotone" dataKey="trend" stroke="#ffcccb" strokeWidth={2} dot={false}/>
                    </LineChart>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            textAlign: "center"
                        }}
                    >

                        {asins.slice(0, props.numAsins).map((asin: string, index: number) => {
                            return <a
                                href={`https://www.amazon.com/dp/${asin}?&_encoding=UTF8&tag=trendfriend02-20&linkCode=ur2&linkId=806a85d9f534e73e015afff7b43fa56a&camp=1789&creative=9325`}
                                target={'_blank'}>
                                {imgLoaded ?
                                    <img
                                        src={`https://images.amazon.com/images/P/${asin}.01._SCLZZZZZZZ_.jpg`}
                                        width={`${asinImageSize}px`}
                                        height={`${asinImageSize}px`}
                                        style={{
                                            margin: '3%',
                                            borderRadius: '20px',
                                            borderWidth: '3px',
                                            borderColor: '#ffbb96',
                                            borderStyle: "solid"
                                        }}
                                    >

                                    </img> : <Spin/>}
                            </a>
                        })}
                    </div>

                    {props.showStats ? <div>
                        <h3>{props.granularity.toUpperCase()} Stats</h3>
                        <p><span style={{fontWeight: "bold"}}> Total Search Growth: </span> {termData['volume_growth']}
                        </p>
                        <p><span
                            style={{fontWeight: "bold"}}> Growth Percent: </span> {(100 * termData['volume_growth_percent']).toFixed(2)} %
                        </p>
                        <p><span
                            style={{fontWeight: "bold"}}> Most {props.granularity} searches: </span> {termData['best_volume']}
                        </p>
                        <p><span
                            style={{fontWeight: "bold"}}> Last {props.granularity} searches: </span> {termData['last_volume']}
                        </p>
                        <p><span
                            style={{fontWeight: "bold"}}> Worst {props.granularity} searches: </span> {termData['worst_volume']}
                        </p>
                    </div> : null}
                </div>
            }
        </div>

    )

}

export default ChartContainer;
