import * as React from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';
import LoginContainer from "../Containers/LoginContainer";
import SignUpContainer from "../Containers/SignUpContainer";
import ConfirmEmailContainer from "../Containers/ConfirmEmailContainer";
import PasswordResetContainer from "../Containers/PasswordResetContainer";
import ForgotPasswordContainer from "../Containers/ForgotPasswordContainer";
import AdminView from "../Containers/AdminView";
import HomeContainer from "../Containers/HomeContainer";
import {BrowserView, MobileView} from "react-device-detect";
import TermsContainer from "../Containers/TermsContainer";
import TermsMobileContainer from "../Containers/TermsMobileContainer";
import TermDetailsContainer from "../Containers/TermDetailsContainer";
import TermDetailsMobileContainer from "../Containers/TermDetailsMobileContainer";

class AppRouter extends React.Component {
    render() {
        return (
            <div>
                <Router>
                    <Switch>
                        {/*TODO Add logic to handle login session*/}
                        <Route exact={true} path="/login" component={LoginContainer}/>
                        <Route exact={true} path="/signup" component={SignUpContainer}/>
                        <Route exact={true} path="/verify-code" component={ConfirmEmailContainer}/>
                        <Route exact={true} path="/reset-password" component={PasswordResetContainer}/>
                        <Route exact={true} path="/forgot-password" component={ForgotPasswordContainer}/>
                        <Route exact={true} path="/admin-view" component={AdminView}/>
                        <Route exact={true} path="/home" component={HomeContainer}/>

                        <Route exact={true} path="/search">
                            <BrowserView>
                                <TermsContainer/>
                            </BrowserView>
                            <MobileView>
                                <TermsMobileContainer/>
                            </MobileView>

                        </Route>
                        <Route exact={true} path="/trending/:urlEncodeTermId">
                            <BrowserView>
                                <TermDetailsContainer/>
                            </BrowserView>
                            <MobileView>
                                <TermDetailsMobileContainer/>
                            </MobileView>
                        </Route>
                        <Route path="/">
                            <Redirect to="/home"/>
                        </Route>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default AppRouter;
