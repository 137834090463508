import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {Spin, Table, Tag} from "antd";
import {Line, XAxis, Tooltip, Legend, Bar, YAxis, ComposedChart} from 'recharts';
import moment from 'moment';

import useAuthUser from "../../Hooks/auth/useAuthUser";
import {isAdmin} from "../../Hooks/auth/auth-utils";
import {useHistory} from "react-router-dom";
import {useAPI} from "../../API/Hooks";
import {USER_POOL_ID} from "../../Utils/constants";


const AdminView = () => {
    const {authUser, authPending} = useAuthUser();
    const [users, setUsers] = useState([])
    const [traction, setTraction] = useState([])
    const history = useHistory();

    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '15%'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_: any, {email}: any) => (
                <a target={"_blank"} href={`mailto:${email}`}> {email} </a>
            )
        },
        {
            title: 'Website',
            dataIndex: 'website',
            key: 'website',
        },
        {
            title: 'Verified',
            dataIndex: 'email_verified',
            key: 'email_verified',
            width: '12%'
        },
        {
            title: 'Created On',
            dataIndex: 'created_on',
            key: 'created_on',
            render: (_: any, {created_on}: any) => (
                <div> {moment(created_on).format('YYYY-MM-DD')} </div>
            )
        }
    ];

    useEffect(() => {
        if (authUser.email) {
            if (!isAdmin(authUser.email)) {
                history.push('/search')
            }
        }

    }, [authUser])

    const {
        data,
        dataReady,
        error
    } = useAPI('list-users', {
        'pool_id': USER_POOL_ID,
    }, response => {
        console.log(response)

        setUsers(response['users'])
        setTraction(response['traction'])

        return response
    })

    return (
        <div style={{
            marginTop: "3%",
            textAlign: "center"
        }}>

            {dataReady ? <h1>Admin View</h1> : <Spin/>}
            {dataReady ? <div

                style={{
                    display: "flex",
                    justifyContent: "space-around"
                }}

            >
                <div
                    style={{
                        width: "45%",
                    }}
                >
                    <h1>{users.length} Signed-Up Users</h1>
                    <Table
                        dataSource={users}
                        columns={tableColumns}
                        pagination={{pageSize: 20}}
                        scroll={{
                            y: 600,
                            scrollToFirstRowOnChange: true
                        }}


                    />
                </div>
                <div
                    style={{
                        width: '45%',
                    }}
                >
                    <h1>User Traction over Time</h1>
                    <ComposedChart width={600} height={600} data={traction.map((x: any, index: number) => {
                        return {
                            date: x['date'],
                            'New User Count' : x['traction'],
                            'Total User Count' : x['traction_agg']
                        }
                    })}>
                        <Legend/>
                        <Tooltip
                            position={{
                                y: 175
                            }}
                        />
                        <XAxis dataKey="date"/>
                        <YAxis dataKey="Total User Count"/>
                        <Bar dataKey="New User Count" fill="#8884d8"/>
                        <Line type="monotone" dataKey="Total User Count" stroke="red" strokeWidth={2} dot={false}/>
                    </ComposedChart>
                </div>

            </div> : <Spin/>}
        </div>

    )
}
export default withRouter(AdminView);
