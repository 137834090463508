import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {Button, Icon, Tag} from "antd";
import {BrowserView, MobileView} from 'react-device-detect';
import {isMobile} from 'react-device-detect';
import ChartContainer from "../ChartContainer";
import {urlEncodeTerm} from "../../API/Utils";


const Home = () => {

    const [numAsins, setNumAsins] = useState(2);

    const [termDaily, setTermDaily] = useState("hareem al sultan gold");
    const [termWeekly, setTermWeekly] = useState("sugar wax roller");
    const [termMonthly, setTermMonthly] = useState("bubble skincare");

    useEffect(() => {

        if (isMobile) {
            console.log("is mobile");
            setNumAsins(Math.min(2, numAsins))
        } else {
            console.log("is not mobile");
        }
    }, [])


    return (
        <div
            style={{
                textAlign: "center",
                background: "white",
                padding: "5%",
            }}


        >
            <div>
                <MobileView>
                    <img
                        src={"https://ecomtrendfinder-resources.s3.amazonaws.com/EcomTrendFinderLogo.jpeg"}
                        width={"50%"}
                    />
                </MobileView>

                <BrowserView>
                    <img
                        src={"https://ecomtrendfinder-resources.s3.amazonaws.com/EcomTrendFinderLogo.jpeg"}
                        width={"20%"}
                    />
                </BrowserView>

                <h3>Browse through the top searched terms and identify trends before they take off</h3>
            </div>

            <div
                style={{
                    marginTop: "3%",
                }}
            >
                <a href={"/search"}>
                    <Button
                        type="primary"
                        style={{
                            fontSize: "1.3em",
                        }}
                    >
                        Try it!
                    </Button>
                </a>

            </div>

            <div
                style={{
                    marginTop: "3%",
                    display: "flex",
                    justifyContent: "space-evenly",
                }}
            >
                <BrowserView>

                    <div>
                        <ChartContainer
                            term={termMonthly}
                            granularity={"monthly"}
                            showStats={false}
                            numAsins={numAsins}
                        />
                        <div>
                            <a href={`/trending/${urlEncodeTerm(termMonthly)}`} target={'_blank'}>
                                <Tag color={'purple'} key={termMonthly} style={{
                                    cursor: 'pointer',
                                    width: '80%',
                                    margin: '10%',
                                    textAlign: 'center',
                                }}>
                                    DETAILS
                                </Tag>
                            </a>
                        </div>
                    </div>
                </BrowserView>

                <div>
                    <ChartContainer
                        term={termWeekly}
                        granularity={"weekly"}
                        showStats={false}
                        numAsins={numAsins}
                    />
                    <div>
                        <a href={`/trending/${urlEncodeTerm(termWeekly)}`} target={'_blank'}>
                            <Tag color={'purple'} key={termWeekly} style={{
                                cursor: 'pointer',
                                width: '80%',
                                margin: '10%',
                                textAlign: 'center',
                            }}>
                                DETAILS
                            </Tag>
                        </a>
                    </div>
                </div>

                <BrowserView>
                    <div>
                        <ChartContainer
                            term={termDaily}
                            granularity={"daily"}
                            showStats={false}
                            numAsins={numAsins}
                        />
                        <div>
                            <a href={`/trending/${urlEncodeTerm(termDaily)}`} target={'_blank'}>
                                <Tag color={'purple'} key={termDaily} style={{
                                    cursor: 'pointer',
                                    width: '80%',
                                    margin: '10%',
                                    textAlign: 'center',
                                }}>
                                    DETAILS
                                </Tag>
                            </a>
                        </div>
                    </div>
                </BrowserView>

            </div>

            <div
                style={{
                    marginTop: "3%",
                }}
            >
                <h1>How it Works?</h1>
            </div>

            <div
                style={{
                    marginTop: "3%",
                    display: "flex",
                    justifyContent: "space-evenly",
                }}
            >
                <div>
                    <p
                        style={{
                            fontSize: "1.2em",
                        }}
                    >
                        <Icon style={{
                            marginRight: "1%",
                        }} type="robot"/>
                        We run AI and Machine Learning techniques on terra bytes worth of search data to bring you the
                        insights you
                        need to make the best possible when running E-Commerce business.
                    </p>

                    <p
                        style={{
                            fontSize: "1.2em",
                        }}
                    >
                        <Icon style={{
                            marginRight: "1%",
                        }} type="dashboard"/>
                        We provide an intuitive dashboard that allows you to quickly query for the most popular terms in
                        the categories that interest you the most.
                    </p>

                    <p
                        style={{
                            fontSize: "1.2em",
                        }}
                    >
                        <Icon style={{
                            marginRight: "1%",
                        }} type="trophy"/>

                        We issue an exclusive, daily newsletter with the top trending terms to help you stay ahead of
                        the competition

                    </p>
                </div>

                <BrowserView>

                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >

                            <div style={{
                                width: "45%",
                            }}>
                                <p
                                    style={{
                                        fontSize: "1.4em",
                                        fontStyle: "italic",
                                    }}
                                >"E Com Trend Finder drives our business forward and helps us sell products
                                    that are in the direction of current trends. "</p>
                            </div>


                        </div>

                    </div>
                </BrowserView>

            </div>

            <div>

            </div>

            <div
                style={{
                    marginTop: "3%",
                }}
            >
                <a href={"/search"}>
                    <Button
                        type="primary"
                        style={{
                            fontSize: "1.3em",
                        }}
                    >
                        Try it!
                    </Button>
                </a>
            </div>

            <div
                style={{
                    marginTop: "3%",
                }}>
                <h1>Trend Alerts</h1>
                <p
                    style={{
                        fontSize: "1.2em",
                    }}
                >
                    By <a href={'/signup'}>signing </a> up for Trend Finder, you also get automatically
                    enrolled into our daily Trend Alerts Newsletter. See example issues below!
                </p>
            </div>


            <div
                style={{
                    marginTop: "3%",
                    display: "flex",
                    justifyContent: "space-around",
                }}
            >

                <BrowserView>
                    <div>
                        <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7042867228043595777"
                                width={"100%"} height={"600px"} frameBorder="1" allowFullScreen={false}
                                title="Embedded post"></iframe>
                    </div>
                </BrowserView>


                <div>
                    <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7045411972598665216"
                            width={"100%"} height={"600px"} frameBorder="1" allowFullScreen={false}
                            title="Embedded post"></iframe>
                </div>

                <BrowserView>
                    <div>
                        <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7043749562448056321"
                                width={"100%"} height={"600px"} frameBorder="1   " allowFullScreen={false}
                                title="Embedded post"></iframe>
                    </div>
                </BrowserView>

            </div>
        </div>

    )

}

export default withRouter(Home);
