// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig =  {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "eu-west-1:d03af6bd-4d0e-45ef-a027-f03db88c5b02",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_phU7kMkdk",
    "aws_user_pools_web_client_id": "51bbfh3d0l844rvs339lghd9ua",
};


export default awsconfig;