import React, {useEffect, useState} from "react";
import {backendCall, getSanitisedTerm, urlEncodeTerm} from "../../API/Utils";
import {Spin} from "antd";
import {LineChart, Line, XAxis, Tooltip as ChartTooltip, Legend} from 'recharts';
import {isLoggedIn} from "../../Hooks/auth/auth-utils";
import useAuthUser from "../../Hooks/auth/useAuthUser";

type ChartProps = {
    term: string;
    numWordsAsPrefix: number;
    numRelatedTerms: number;
}

const RelatedTermsContainer = (props: ChartProps) => {

    const [terms, setTerms] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dataExists, setDataExists] = useState(false);
    const {authUser, authPending} = useAuthUser()

    useEffect(() => {
        backendCall('/get-related-terms', {
            is_test: '0',
            email: authUser.email,
            term: props.term,
            num_words_as_prefix: props.numWordsAsPrefix,
            num_related_terms: props.numRelatedTerms,

        }, r => r).then((r: any) => {
                if (r['result']['terms'] && r['result']['terms'].length > 0) {
                    setTerms(r['result']['terms']);
                    setDataExists(true);
                    setDataLoaded(true);

                } else {
                    setDataExists(false);
                    setDataLoaded(true);
                }

            }
        )

    }, [])


    return (
        <div>
            {!dataLoaded ? <Spin/> :
                <div style={{
                    textAlign: "center",
                }}>
                    {!dataExists ? null : <h3>Related Terms</h3>}
                    {!terms ? null : terms.map((term: any) => {
                        return <div>
                            <a href={`/trending/${urlEncodeTerm(term)}`}>"{term}"</a>
                        </div>
                    })}
                </div>}
        </div>

    )

}

export default RelatedTermsContainer;
